.label-large {
  font-family: 'IBM Plex Mono' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.label-medium {
  font-family: 'Roboto' !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.label-small {
  font-family: 'Roboto' !important;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
