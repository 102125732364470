@import "variables.scss";

$sizes: (xs, sm, md, lg, xl);
$paddingMarginSizes: ((xs, $sizexs), (sm, $sizesm), (md, $sizemd), (lg, $sizelg), (xl, $sizexl)); // Leave this variable alone
$sides: ((t, top), (r, right), (b, bottom), (l, left));

@each $sz in $paddingMarginSizes {
  .m-y-#{nth($sz, 1)} {
    margin-right: nth($sz, 2) !important;
    margin-left: nth($sz, 2) !important;
  }

  .p-y-#{nth($sz, 1)} {
    padding-right: nth($sz, 2) !important;
    padding-left: nth($sz, 2) !important;
  }
  .m-x-#{nth($sz, 1)} {
    margin-top: nth($sz, 2) !important;
    margin-bottom: nth($sz, 2) !important;
  }

  .p-x-#{nth($sz, 1)} {
    padding-top: nth($sz, 2) !important;
    padding-bottom: nth($sz, 2) !important;
  }

  @each $side in $sides {
    .m-#{str-slice(nth($side, 1), 0, 1)}-#{nth($sz, 1)} {
      margin-#{nth($side, 2)}: nth($sz, 2) !important;
    }

    .p-#{str-slice(nth($side, 1), 0, 1)}-#{nth($sz, 1)} {
      padding-#{nth($side, 2)}: nth($sz, 2) !important;
    }
  }
}

.hidden-xs-down {
  @include xs {
    display: none!important;
  }
}

.hidden-sm-down {
  @include xs {
    display: none!important;
  }

  @include sm {
    display: none!important;
  }
}

.hidden-md-down {
  @include xs {
    display: none!important;
  }

  @include sm {
    display: none!important;
  }

  @include md {
    display: none!important;
  }
}

.hidden-lg-down {
  @include xs {
    display: none!important;
  }

  @include sm {
    display: none!important;
  }

  @include md {
    display: none!important;
  }

  @include lg {
    display: none!important;
  }
}

.hidden-xl-down {
  @include xs {
    display: none!important;
  }

  @include sm {
    display: none!important;
  }

  @include md {
    display: none!important;
  }

  @include lg {
    display: none!important;
  }

  @include xl {
    display: none!important;
  }
}

.hidden-xs-up {
  @include xs {
    display: none!important;
  }

  @include sm {
    display: none!important;
  }

  @include md {
    display: none!important;
  }

  @include lg {
    display: none!important;
  }

  @include xl {
    display: none!important;
  }
}

.hidden-sm-up {
  @include sm {
    display: none!important;
  }

  @include md {
    display: none!important;
  }

  @include lg {
    display: none!important;
  }

  @include xl {
    display: none!important;
  }
}

.hidden-md-up {
  @include md {
    display: none!important;
  }

  @include lg {
    display: none!important;
  }

  @include xl {
    display: none!important;
  }
}

.hidden-lg-up {
  @include lg {
    display: none!important;
  }

  @include xl {
    display: none!important;
  }
}

.hidden-xl-up {
  @include xl {
    display: none!important;
  }
}
