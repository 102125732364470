@import 'mixins';
@import '@angular/material/theming';
@include mat-core();

// basic
$white: #ffffff;
$black: #000000;
$grey: #f8f9fa;
$neutral: #78767a;
$primary: (
  100: #000569,
  200: #000da4,
  300: #0016e5,
  400: #2e3eff,
  500: #5664ff,
  600: #7a85ff,
  700: #9ca4ff,
  800: #bec2ff,
  900: #e0e0ff,
  950: #f1efff,
  990: #fffbff,
);
$on-primary: #ffffff;
$primary-container: #e0e0ff;
$on-primary-container: #000569;
$primary-fixed: #e0e0ff;
$on-primary-fixed: #000569;
$primary-fixed-dim: #bec2ff;
$on-primary-fixed-variant: #0016e5;

// SECONDARY COLORS
$accent: (
  100: #001f26,
  200: #003640,
  300: #004e5b,
  400: #006879,
  500: #008398,
  600: #009fb8,
  700: #2bbbd6,
  800: #54d7f3,
  900: #a9edff,
  950: #d7f6ff,
  990: #fffbff,
);
$on-accent: #ffffff;
$accent-container: #a9edff;
$on-accent-container: #001f26;
$accent-fixed: #a9edff;
$on-accent-fixed: #001f26;
$accent-fixed-dim: #54d7f3;
$on-accent-fixed-variant: #004e5b;
// TERTIARY COLORS
$tertiary: (
  100: #001d36,
  120: #00213d,
  200: #003258,
  300: #00497d,
  400: #0061a4,
  500: #007acd,
  600: #0095f7,
  700: #64afff,
  800: #9fcaff,
  900: #d1e4ff,
  950: #eaf1ff,
  990: #fdfcff,
);
$on-tertiary: #ffffff;
$tertiary-container: #d1e4ff;
$on-tertiary-container: #001d36;
$tertiary-fixed: #d1e4ff;
$on-tertiary-fixed: #9fcaff;
$tertiary-fixed-dim: #001d36;
$on-tertiary-fixed-variant: #00497d;

// ERROR COLORS
$error: (
  100: #410002,
  200: #690005,
  300: #93000a,
  400: #ba1a1a,
  500: #de3730,
  600: #ff5449,
  700: #ff897d,
  800: #ffb4ab,
  900: #ffdad6,
  950: #ffedea,
  990: #fffbff,
);
$iconic-warn: (
  100: #410002,
  200: #690005,
  300: #93000a,
  400: #ba1a1a,
  500: #de3730,
  600: #ff5449,
  700: #ff897d,
  800: #ffb4ab,
  900: #ffdad6,
  950: #ffedea,
  990: #fffbff,
  contrast: (
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #410002,
    800: #410002,
    900: #410002,
    950: #690005,
    990: #93000a,
  ),
);
$on-warn: #ba1a1a;
$error-container: #ffdad6;
$on-error-container: #410002;

// Neutral colors
$iconic-neutral: (
  40: #0e0e11,
  60: #131316,
  100: #1b1b1f,
  120: #1f1f23,
  170: #2a2a2d,
  200: #303034,
  220: #353438,
  240: #39393c,
  300: #47464a,
  400: #5f5e62,
  500: #78767a,
  600: #929094,
  700: #adaaaf,
  800: #c8c5ca,
  870: #dcd9de,
  900: #e4e1e6,
  920: #eae7ec,
  940: #f0edf1,
  950: #f3f0f4,
  960: #f6f2f7,
  980: #fcf8fd,
  990: #fffbff,
);

// Neutral variant colors
$neutralVariant: (
  40: #0e0e11,
  60: #131316,
  100: #1b1b23,
  120: #1f1f23,
  170: #2a2a2d,
  200: #303038,
  220: #353438,
  240: #39393c,
  300: #46464f,
  400: #5e5d67,
  500: #777680,
  600: #91909a,
  700: #abaab4,
  800: #c7c5d0,
  870: #dcd9de,
  900: #e3e1ec,
  920: #eae7ec,
  940: #f0edf1,
  950: #e3e1ec,
  960: #f6f2f7,
  980: #fcf8fd,
  990: #fffbff,
);
$surfaceDim: #dcd9de;
$surface: #fcf8fd;
$surfaceBright: #fcf8fd;
$surfaceContainerLowest: #ffffff;
$surfaceContainerLow: #f6f2f7;
$surfaceContainer: #f0edf1;
$surfaceContainerHigh: #eae7ec;
$surfaceContainerHighest: #e4e1e6;
$onSurface: #1b1b1f;
$onSurfaceVariant: #46464f;
$outline: #777680;
$outlineVariant: #c7c5d0;
$inverseSurface: #303034;
$inverseOnSurface: #f3f0f4;
$inversePrimary: #bec2ff;
$scrim: #000000;
$shadow: #000000;
$positive: #288a28;
$disabled: #757575;
$light: #e4e1e6;
$iconic-blue: (
  100: #000569,
  200: #000da4,
  300: #0016e5,
  400: #2e3eff,
  500: #5664ff,
  600: #7a85ff,
  700: #9ca4ff,
  800: #bec2ff,
  900: #e0e0ff,
  950: #f1efff,
  990: #fffbff,
  contrast: (
    50: #1e2e72,
    100: #1e2e72,
    150: #1e2e72,
    200: #1e2e72,
    250: #1e2e72,
    300: #1e2e72,
    350: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);

$iconic-accent: (
  100: #001f26,
  200: #003640,
  300: #004e5b,
  400: #006879,
  500: #008398,
  600: #009fb8,
  700: #2bbbd6,
  800: #54d7f3,
  900: #a9edff,
  950: #d7f6ff,
  990: #fffbff,
  contrast: (
    100: #001f26,
    200: #003640,
    300: #004e5b,
    400: #006879,
    500: #008398,
    600: #009fb8,
    700: #2bbbd6,
    800: #54d7f3,
    900: #a9edff,
    950: #d7f6ff,
    990: #fffbff,
  ),
);

// status
$planned: #f88747;
$open: mat-palette($iconic-blue, 500);
$closed: #a9adb9;
$delay-of-repair: #e5c34c;

$iconic-theme: mat-light-theme(
  (
    color: (
      primary: mat-palette($iconic-blue, 400),
      accent: mat-palette($iconic-accent, 400),
      warn: mat-palette($iconic-warn, 400),
    ),
  )
);

// Include the default theme styles (color and default density)
@include angular-material-theme($iconic-theme);

// Define an alternate dark theme.

$primary: map-get($iconic-theme, primary);

$accent: map-get($iconic-theme, accent);

$warn: map-get($iconic-theme, warn);
$foreground: map-get($iconic-theme, foreground);
$neutral: #5f5e62;
$background: map-get($iconic-theme, background);

@mixin print {
  @media print {
    @content;
  }
}

// Device widths
$xs: 480px;
$sm: 600px;
$md: 960px;
$lg: 1280px;
$xl: 1440px;

//Device heights
$hsm: 350px;
$hmd: 600px;

$sizexs: 0.25rem;
$sizesm: 0.5rem;
$sizemd: 1rem;
$sizelg: 1.5rem;
$sizexl: 2rem;
$sizexxl: 2.5rem;

@mixin mix-app-theme($app-theme) {
  $primary: map-get($app-theme, primary);
  $accent: map-get($app-theme, accent);
  .mat-button-toggle-checked {
    background-color: mat-color($accent);
    color: mat-color($accent, default-contrast);
  }
}

@include mix-app-theme($iconic-theme);
