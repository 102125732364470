.title-large {
  font-family: 'Manrope';
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}

.title-medium {
  font-family: 'Manrope';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.title-small {
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.56px;
}
