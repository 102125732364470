/* ----------------------------------------------------------------------------------------------------- */
/*  @ Icon
/* ----------------------------------------------------------------------------------------------------- */
.mat-icon {
  &.sm-icon {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    line-height: 18px;
    font-size: 18px;
  }
}

