/* This injects Tailwind's base styles and any base styles registered by plugins. */
@tailwind base;

/* This injects additional styles into Tailwind's base styles layer. */
@layer base {
  * {
    /* Text rendering */
    text-rendering: optimizeLegibility;
    -o-text-rendering: optimizeLegibility;
    -ms-text-rendering: optimizeLegibility;
    -moz-text-rendering: optimizeLegibility;
    -webkit-text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;

    /* Remove the focus ring */
    &:focus {
      outline: none !important;
    }
  }

  /* HTML and Body default styles */
  html,
  body {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    min-height: 100%;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  /* Font size */
  html {
    font-size: 20px;
  }

  body {
    font-size: 0.875rem;
  }

  /* Stylistic alternates for Inter */
  body {
    font-feature-settings: 'salt';
  }

  /* Better spacing and border for horizontal rule */
  hr {
    margin: 32px 0;
    border-bottom-width: 1px;
  }

  /* Make images and videos to take up all the available space */
  img {
    width: 100%;
    vertical-align: top;
  }

  /* Fix: Disabled placeholder color is too faded on Safari */
  input[disabled] {
    opacity: 1;
    -webkit-text-fill-color: currentColor;
  }

  body,
  .dark,
  .light {
    @apply text-default bg-default #{'!important'};
  }

  *,
  *::before,
  *::after {
    --tw-border-opacity: 1 !important;
    border-color: rgba(var(--iconic-border-rgb), var(--tw-border-opacity));

    .dark & {
      --tw-border-opacity: 0.12 !important;
    }
  }

  [disabled] * {
    @apply text-[#1D1B201F] #{'!important'};
  }

  /* Print styles */
  @media print {
    /* Make the base font size smaller for print so everything is scaled nicely */
    html {
      font-size: 12px !important;
    }

    body,
    .dark,
    .light {
      background: none !important;
    }
  }

  .display-large {
    font-family: 'Roboto';
    font-size: 57px;
    font-style: normal;
    font-weight: 400;
    line-height: 64px;
  }

  .display-medium {
    font-family: 'Roboto';
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 52px;
  }

  .display-small {
    font-family: 'Roboto';
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
  }

  .headline-large {
    font-family: 'Roboto';
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
  }

  .headline-medium {
    font-family: 'Roboto';
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
  }

  .headline-small {
    font-family: 'Roboto';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  .title-large {
    font-family: 'Manrope';
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }

  .title-medium {
    font-family: 'Manrope';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .title-small {
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.56px;
  }

  .label-large {
    font-family: 'IBM Plex Mono';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  .label-medium {
    font-family: 'Roboto';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  .label-small {
    font-family: 'Roboto';
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  .body-large-text {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .body-medium-text {
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .body-small-text {
    font-family: 'Roboto';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .body-large-numbers {
    font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .body-medium-numbers {
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .body-small-numbers {
    font-family: 'Roboto';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}

@tailwind components;
@tailwind utilities;

@layer utilities {
  .scroll-shadow {
    overflow: auto;
    background: /* Shadow covers */ linear-gradient(
        white 30%,
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */
        radial-gradient(
          50% 0,
          farthest-side,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        ),
      radial-gradient(
          50% 100%,
          farthest-side,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        )
        0 100%;
    background: /* Shadow covers */ linear-gradient(
        white 30%,
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */
        radial-gradient(
          farthest-side at 50% 0,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        ),
      radial-gradient(
          farthest-side at 50% 100%,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        )
        0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
  }
}
