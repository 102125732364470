@import '../../../../../../node_modules/ag-grid-community/src/styles/ag-grid.scss';
@import '../../../../../../node_modules/ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin';
@import '../../../variables.scss';

.ag-theme-basic-list {
  .ag-cell {
    line-height: 40px !important;
    font-family: 'IBM Plex Sans', sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
  }
  .ag-icon.ag-icon-tree-closed {
    color: #000 !important;
  }
  .ag-icon.ag-icon-tree-open,
  .ag-icon.ag-icon-tree-closed {
    color: #000 !important;
  }
  .ag-wrapper.ag-input-wrapper.ag-text-field-input-wrapper {
    height: 30px !important;
    .ag-input-field-input.ag-text-field-input {
      padding-bottom: 0px !important;
      height: 30px !important;
    }
  }
  .ag-paging-panel {
    color: #000 !important;
    .ag-paging-button {
      color: #181d1f !important;
    }
  }

  .ag-tabs.ag-menu.ag-focus-managed.ag-ltr.ag-popup-child {
    background-color: #001d36 !important;
    color: #fff !important;
    .ag-input-field-input.ag-text-field-input {
      color: #fff !important;
    }
  }

  .ag-root-wrapper.ag-layout-normal.ag-ltr {
    border-radius: 5px !important;
  }

  .ag-header-cell-text {
    font-family: 'Manrope' !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 700 !important;
  }

  @include ag-theme-material(
    (
      foreground-color: #000,
      background-color: #fff,
      border-color: #c7c5d0,
      secondary-border-color: #f6f2f7,
      header-background-color: #001d36,
      odd-row-background-color: #fff,
      modal-overlay-background-color: #fff,
      tooltip-background-color: #fff,
      alpine-active: #2e3eff,
      control-panel-background-color: #001d36,
      subheader-background-color: #001d36,
      input-disabled-background-color: rgba(104, 104, 110, 0.07),
      disabled-foreground-color: rgba(255, 255, 255, 0.5),
      chip-background-color: rgba(255, 255, 255, 0.07),
      input-disabled-border-color: rgba(104, 104, 110, 0.3),
      row-hover-color: #f6f2f7,
      input-border-color: #646464,
      data-color: #181d1f,
      selected-row-background-color: #f6f2f7,
      column-hover-color: rgba(33, 150, 243, 0.1),
      input-focus-border-color: rgba(33, 150, 243, 0.4),
      range-selection-background-color: rgba(33, 150, 243, 0.2),
      range-selection-background-color-2: rgba(33, 150, 243, 0.36),
      range-selection-background-color-3: rgba(33, 150, 243, 0.49),
      range-selection-background-color-4: rgba(33, 150, 243, 0.59),
      checkbox-unchecked-color: #999999,
      checkbox-background-color: #e0e0ff,
      checkbox-checked-color: #2e3eff,
      range-selection-border-color: #d1e4ff,
      secondary-foreground-color: #fff,
      side-button-selected-background-color: rgba(0, 0, 0, 0),
      selected-tab-underline-color: #00497d,
      header-foreground-color: #fff,
      subheader-toolbar-background-color: rgba(255, 255, 255, 0),
      range-selection-highlight-color: #9fcaff,
      range-selection-chart-category-background-color: rgba(0, 255, 132, 0.1),
      range-selection-chart-background-color: rgba(0, 88, 255, 0.1),
      value-change-value-highlight-background-color: rgba(22, 160, 133, 0.5),
      value-change-delta-up-color: #288a28,
      value-change-delta-down-color: #e53935,
      borders-secondary: #ffffff,
      row-border-color: #c7c5d0,
      header-cell-hover-background-color: rgba(255, 255, 255, 0),
      borders-critical: #ffffff,
      borders-input: ag-derived(ag-borders-secondary),
      header-column-separator-color: ag-derived(ag-secondary-border-color),
      header-column-resize-handle-color: ag-derived(ag-secondary-border-color),
      toggle-button-on-background-color: ag-derived(checkbox-checked-color),
      toggle-button-off-background-color: ag-derived(checkbox-unchecked-color),
      toggle-button-on-border-color: ag-derived(checkbox-checked-color),
      checkbox-indeterminate-color: ag-derived(checkbox-unchecked-color),
      toggle-button-off-border-color: ag-derived(checkbox-unchecked-color),
      toggle-button-switch-background-color: #fff,
      font-size: 14px,
      grid-size: 10px,
      border-radius: 10px,
      header-column-resize-handle-display: 0px,
      header-column-resize-handle-height: 30px,
      icon-size: 16px,
      header-height: 42px,
      list-item-height: 24px,
      column-select-indent-size: ag-derived(icon-size),
      set-filter-indent-size: ag-derived(icon-size),
      cell-horizontal-padding: 18px,
      cell-widget-spacing: 12px,
      widget-container-vertical-padding: 12px,
      widget-container-horizontal-padding: 12px,
      widget-vertical-spacing: 9px,
      toggle-button-height: 18px,
      selected-tab-underline-width: 2px,
      tab-min-width: 240px,
      row-width: 100px,
      checkbox-border-radius: ag-derived(border-radius),
      toggle-button-border-width: 1px,
      filter-tool-panel-group-indent: 16px,
      row-border-width: 1px,
      header-column-separator-width: 1px,
      widget-horizontal-spacing: 12px,
      card-radius: ag-derived(border-radius),
      header-column-resize-handle-width: 1px,
      filter-option: #f3c08b,
      filter-join: #f08e8d,
      filter-colunm: #a6e194,
      filter-value: #85c0e4,
    )
  );
}
