

//width mixins
@mixin xs {
  @media (max-width: #{$sm - 1px}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$sm}) and (max-width: #{$md - 1px}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$md}) and (max-width: #{$lg - 1px}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$lg}) and (max-width: #{$xl - 1px}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$xl}) {
    @content;
  }
}

//height mixins
@mixin hxs {
  @media (max-height: #{$hsm - 1px}) {
    @content;
  }
}

@mixin hsm {
  @media (min-width: #{$hsm} and (max-width: #{$hmd - 1px})) {
    @content;
  }
}

@mixin hmd {
  @media (min-width: #{$hmd}) {
    @content;
  }
}
