/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'variables';
@import 'reset';
@import 'utilities';
$ag-mat-icons-path: '~ag-grid-community/src/styles/material-icons/';
$ag-icons-path: '~ag-grid-community/src/styles/icons/';

@import '~ag-grid-community/src/styles/ag-grid';

// import for the ng2-date-picker module
@import '~@angular/cdk/overlay-prebuilt.css';

// set the colors to the ones from the material theme
$ag-mat-primary: mat-color($primary, default);
$ag-mat-accent: mat-color($accent, default);

@import '~ag-grid-community/dist/styles/ag-theme-material.css';

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url('./fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Bold';
  font-weight: 400;
  font-style: normal;
  src: url('./fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-weight: 400;
  font-style: normal;
  src: url('./fonts/IBMPlexMono-Regular.woff') format('woff'),
    url('./fonts/IBMPlexMono-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-weight: 800;
  font-style: normal;
  src: url('./fonts/IBMPlexMono-Bold.woff') format('woff'),
    url('./fonts/IBMPlexMono-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Roobert-Regular';
  font-weight: 400;
  font-style: normal;
  src: url('./fonts/Roobert-Regular.woff') format('woff'),
    url('./fonts/Roobert-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Roobert-Regular';
  font-weight: 800;
  font-style: normal;
  src: url('./fonts/Roobert-Bold.woff') format('woff'),
    url('./fonts/Roobert-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

* {
  font-family: 'Roboto', ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}

a {
  cursor: pointer;
  color: mat-color($primary, default);
}

h3,
h4,
p {
  font-family: 'Roboto', ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji' !important;
}

.container {
  margin: 0 auto;
  width: 100%;

  @include xs {
    max-width: 100%;
  }

  @include sm {
    max-width: 540px;
  }

  @include md {
    max-width: 768px;
  }

  @include lg {
    max-width: 992px;
  }

  @include xl {
    max-width: 1200px;
  }
}

.mobile-back {
  mat-icon {
    font-size: 0.75rem;
    width: 0.75rem;
    height: 0.75rem;
    line-height: 0.75rem;
  }
}

.force-mat-error {
  display: block;
  font-size: 12px;
  margin-top: -1rem;
  margin-bottom: 1rem;
}

.mat-list-base .mat-list-item .mat-list-text > *,
.mat-list-base .mat-list-option .mat-list-text > * {
  line-height: 1.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji' !important;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6,
.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab,
.mat-list-item,
.mat-list-base,
.mat-subheader,
.mat-tab-label-content,
.mat-tab-link,
button.mat-menu-item,
.mat-button-toggle,
.mat-option,
.mat-select {
  font-family: 'Roboto', ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji' !important;
}

button.mat-button,
button.mat-flat-button,
button.mat-icon-button,
button.mat-stroked-button,
button.mat-raised-button {
  .mat-button-wrapper {
    font-family: 'Roboto', ui-sans-serif, system-ui, -apple-system,
      BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
      'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol', 'Noto Color Emoji' !important;

    span {
      font-family: 'Roboto', ui-sans-serif, system-ui, -apple-system,
        BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol', 'Noto Color Emoji' !important;
    }
  }
}

button.mat-menu-item,
button.mat-menu-item span {
  font-family: 'Roboto', ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji' !important;
  cursor: pointer;
}

button.mat-button,
button.mat-flat-button,
button.mat-stroked-button,
button.mat-raised-button {
  &.raiser {
    transition: 0.5s;

    &:hover {
      box-shadow: 0 0.5em 0.5em -0.4em #000000 !important;
      transform: translateY(-0.05em) !important;
    }
  }

  .mat-icon {
    width: 1rem;
    height: 1rem;
    font-size: 1rem;
    line-height: 1rem;
  }
}
button.mat-raised-button:hover {
  box-shadow: 0 0.5em 0.5em -0.4em #777fa6;
  transform: translateY(-0.05em);
}

.full-screen-dialog {
  position: absolute;
  bottom: 0;
  height: 100dvh;
  top: 0;
  right: 0;
  bottom: 0;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: fadeInRightBig;

  width: 100vw;
  max-width: 100vw !important;

  .mat-dialog-container {
    padding: 0px;
    border-radius: 0px;
    background: transparent;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0),
      0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.no-padding-dialog {
  .mat-dialog-container {
    padding: 0px;
  }
}

.normal-dialog {
  height: auto;
  width: 80vw !important;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: fadeInRightBig;

  .mat-dialog-container {
    padding: 8px;
    height: inherit;
  }
}

.normal-dialog-auto-width {
  height: auto;
  width: auto !important;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: fadeInRightBig;

  .mat-dialog-container {
    padding: 8px;
    height: inherit;
  }
}

.users-dialog {
  height: 100dvh;
  top: 0;
  right: 0;
  bottom: 0;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: fadeInRightBig;

  @include xs {
    width: 80vw;
  }

  @include sm {
    width: 80vw;
  }

  @include md {
    width: 60vw;
  }

  @include lg {
    width: 60vw;
  }

  @include xl {
    width: 60vw;
  }

  .mat-dialog-container {
    padding: 0px;
    border-radius: 0px;
  }
}

.customers-dialog,
.companies-dialog {
  height: 100dvh;
  top: 0;
  right: 0;
  bottom: 0;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: fadeInRightBig;

  @include xs {
    width: 80vw;
  }

  @include sm {
    width: 80vw;
  }

  @include md {
    width: 60vw;
  }

  @include lg {
    width: 60vw;
  }

  @include xl {
    width: 60vw;
  }

  .mat-dialog-container {
    padding: 0px;
    border-radius: 0px;
  }
}

.file-drop {
  .ngx-file-drop__drop-zone {
    height: inherit !important;
    border: none !important;

    .ngx-file-drop__content {
      height: inherit !important;
    }
  }
}

.no-margin-select {
  .mat-form-field-wrapper {
    margin-bottom: -1rem;
  }
}

.cdk-drag.leak-asset {
  img {
    max-width: 210px;
  }
  &.cdk-drag-preview .delete {
    display: none;
  }
}

.mat-snack-bar-container {
  background: mat-color($primary, default);

  .mat-simple-snackbar {
    font-family: Roobert-Regular !important;

    .mat-simple-snackbar-action {
      color: mat-color($primary, default-contrast);
    }
  }
}

.modal-fix {
  .mat-dialog-container {
    padding: 0px;
    height: inherit;
  }
}

mat-form-field {
  width: 15.625em;
}

.text-primary {
  color: mat-color($primary, default);
}

.dashboard-select.mat-form-field {
  width: 13.5rem;
  font-size: 1rem !important;
  color: mat-color($accent, default-contrast);

  &.inverse {
    width: 18rem;
    color: mat-color($accent, default-contrast);

    .mat-select-arrow-wrapper {
      .mat-select-arrow {
        color: rgba(0, 0, 0, 0.54);
      }
    }

    .mat-form-field-wrapper {
      .mat-select-trigger {
        .mat-select-value {
          .mat-select-value-text {
            mat-select-trigger {
              color: #000;
            }
          }
        }
      }

      .mat-form-field-flex {
        .mat-form-field-outline {
          .mat-form-field-outline-gap {
            background: $white;
          }

          .mat-form-field-outline-end {
            background: $white;
          }
        }
      }
    }
  }

  .mat-select-arrow-wrapper {
    transform: translateY(0);

    .mat-select-arrow {
      color: rgba(255, 255, 255, 0.54);
    }
  }

  .mat-date-range-input
    ~ .mat-form-field-label-wrapper
    > .mat-form-field-label
    > mat-label {
    font-family: 'Roboto', ui-sans-serif, system-ui, -apple-system,
      BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
      'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol', 'Noto Color Emoji' !important;
  }

  .mat-select-trigger {
    color: mat-color($accent, default-contrast);

    mat-select-trigger,
    mat-select-trigger span {
      font-family: 'Roboto', ui-sans-serif, system-ui, -apple-system,
        BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol', 'Noto Color Emoji' !important;
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.5em 0 1em 0;
  }

  .mat-form-field-wrapper {
    margin-bottom: -1em;

    .mat-select-trigger {
      .mat-select-value {
        .mat-select-value-text {
          mat-select-trigger {
            color: #fff;
            margin-left: 0.5em;
          }
        }
      }
    }

    .mat-form-field-flex {
      .mat-form-field-infix {
        padding: 0.5em 0 1em 0;
      }
      .mat-form-field-outline {
        padding: 0;

        .mat-form-field-outline-start {
          background: transparent;
          border: 0px;
        }

        .mat-form-field-outline-gap {
          background: mat-color($accent, 400);
        }

        .mat-form-field-outline-end {
          min-width: 0px;
          border-radius: 500px;
          border-left-style: solid;
          background: mat-color($accent, 400);
        }
      }
    }
  }
  /* this changes the styling on any angular material form field that gets disabled. */
  /* &.disabled-form-field {
    .mat-form-field-wrapper {
      .mat-select-trigger {
        .mat-select-value {
          .mat-select-value-text {
            mat-select-trigger {
              color: mat-color($primary, 300) !important;
            }
          }
        }
      }

      .mat-form-field-flex {
        .mat-form-field-outline {
          .mat-form-field-outline-gap {
            background: mat-color($primary, 200);
          }

          .mat-form-field-outline-end {
            background: mat-color($primary, 200);
          }
        }
      }
    }
  } */
}

.dashboard-select-panel.mat-select-panel .mat-optgroup-label {
  white-space: pre-wrap;
  text-overflow: inherit;
  width: 100%;
  background: #ececec;
  display: flex;
  flex-wrap: wrap;
  height: inherit;
  font-size: 0.75rem;
}

.user-menu.mat-menu-panel {
  min-width: calc(206px - 2rem);
}

.mat-slide-toggle-content {
  font-family: Roobert-Regular !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Button Toggle - pulled from angular material override and modified to match the iconic blue.
/* ----------------------------------------------------------------------------------------------------- */
.mat-button-toggle-group {
  /* border-radius: .25rem !important; */

  /* @apply space-x-1; */

  &.mat-button-toggle-group-appearance-standard {
    border-radius: 8px;
    .mat-button-toggle + .mat-button-toggle {
      /*  background-clip: padding-box; */
    }
  }

  .mat-button-toggle {
    overflow: hidden;
    border: none;

    .mat-icon {
      color: mat-color($accent, 900);
      height: 32px;
    }
    /*  border: none !important; */
    /* border: .1rem solid mat-color($primary, 600) !important; */
    font-weight: 500;

    &.mat-button-toggle-checked {
      /* border-radius: .25rem; */

      .mat-button-toggle-focus-overlay {
        border-bottom: solid 0rem;
        /*  @apply text-default #{'!important'}; */
      }
    }

    .mat-button-toggle-label-content {
      /*  padding: 0 20px; */
      /*  @apply text-secondary; */
      line-height: 32px;
      padding: 0 8px;
    }
  }
}
.iconic-button-toggle-group {
  /* border-radius: .25rem !important; */

  /* @apply space-x-1; */

  &.mat-button-toggle-group-appearance-standard {
    border-radius: 20px;
    .mat-button-toggle + .mat-button-toggle {
      /*  background-clip: padding-box; */
    }
  }

  .mat-button-toggle.mat-button-toggle-checked {
    border: 1px solid #54d7f3;
    background-color: #54d7f3;
    color: #000000;
  }

  .mat-button-toggle {
    overflow: hidden;
    //border: 1px solid #000;

    .mat-icon {
      color: mat-color($accent, 900);
      height: 32px;
    }
    /*  border: none !important; */
    /* border: .1rem solid mat-color($primary, 600) !important; */
    font-weight: 500;

    &.mat-button-toggle-checked {
      /* border-radius: .25rem; */

      .mat-button-toggle-focus-overlay {
        border-bottom: solid 0rem;
        /*  @apply text-default #{'!important'}; */
      }
    }

    .mat-button-toggle-label-content {
      /*  padding: 0 20px; */
      /*  @apply text-secondary; */
      line-height: 36px;
      padding: 0 20px;
    }
  }
}

.mat-tooltip {
  color: white !important;
  font-size: 1rem !important;
  text-align: left;
  font-family: 'Roobert-Regular';
}

.mat-checkbox {
  .mat-checkbox-layout {
    width: 100%;
  }
}

.mat-select-placeholder {
  margin-left: 0.5rem;
  color: mat-color($primary, 600);
}

// mat chip list removes hover color
.mat-chip.mat-standard-chip::after {
  background: none !important; // color that you want on hover
}

.absolute-force {
  router-outlet + * {
    height: 100%;
  }
}
router-outlet + * {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  flex-direction: column;
  @screen md {
    flex-direction: row;
  }
}

app-custom-date .mat-form-field-wrapper {
  margin-bottom: 0px !important;
}

.ag-header-cell:nth-child(6) .ag-floating-filter-button {
  margin-left: 10px !important;
}

.dialog-flush-bottom-footer > mat-dialog-container {
  padding-bottom: 0px;
}

.mat-sort-header-arrow {
  color: white !important;
}

.opacity-72 {
  opacity: 0.72;
}

span.highlighted {
  background: #b1d5fd !important;
}

.dialog-header {
  height: 30px;
}

.dialog-body {
  max-height: calc(100vh - 30px);
  overflow-y: auto;
}

.small-spinner {
  width: 50px;
  height: 50px;

  /* The !important is required to override the default svg dimension */
  svg {
    width: 50px !important;
    height: 50px !important;
  }

  /* This will scale the spinner down to half of its original size */
  transform: scale(0.5);
}

/* ----------------------------------------------------------------------------------------------------- */
/*  Custom Checkbox and Div Colors - Just add a color name to mat-checkbox class or div class.
/*  For example, <mat-checkbox class="checkbox-color-a66eff"> </mat-checkbox>
/*               <div class="div-bg-checkbox-color-a66eff"></div>
/* ----------------------------------------------------------------------------------------------------- */
$checkbox-colors: (
  checkbox-color-6929c5: #6929c5,
  checkbox-color-1192e9: #1192e9,
  checkbox-color-005d5d: #005d5d,
  checkbox-color-9f1853: #9f1853,
  checkbox-color-fa4e56: #fa4e56,
  checkbox-color-520408: #520408,
  checkbox-color-197f38: #197f38,
  checkbox-color-002d9d: #002d9d,
  checkbox-color-ee5398: #ee5398,
  checkbox-color-b28500: #b28500,
  checkbox-color-009d9a: #009d9a,
  checkbox-color-01274a: #01274a,
  checkbox-color-8a3800: #8a3800,
  checkbox-color-a66eff: #a66eff,
  checkbox-color-accent-fixed-dim: #54d7f3
    // add additional checkbox colors here,
);

// Existing checkbox color mixin
@mixin checkbox-color($color-name, $color-value) {
  .#{$color-name}.mat-checkbox-checked .mat-checkbox-background,
  .#{$color-name}.mat-checkbox-checked .mat-checkbox-checkmark {
    background-color: $color-value !important;
    color: $color-value !important;
  }
  .#{$color-name}.mat-checkbox .mat-checkbox-frame {
    border-color: $color-value !important;
  }
}

@each $color-name, $color-value in $checkbox-colors {
  @include checkbox-color($color-name, $color-value);
}

// New mixin for setting background color on a div
@mixin div-bg-color($color-name, $color-value) {
  .div-bg-#{$color-name} {
    background-color: $color-value !important;
  }
}

// Iterate through the colors and apply the div background color mixin
@each $color-name, $color-value in $checkbox-colors {
  @include div-bg-color($color-name, $color-value);
}

app-root {
  height: 100%;
}

.dialog-class {
  max-width: 312px !important;
  .mat-dialog-container {
    border-radius: 28px !important;
    background-color: #eae7ec;
  }
  .mat-button-wrapper {
    color: blue;
  }
  button {
    background-color: transparent;
  }
  .mat-slide-toggle-thumb {
    background: #eae7ec;
  }
}

#section-header {
  border: #d9d9d9;
  border-width: 0.5px;
  border-style: solid;
}

.header-text {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  font-family: 'Roobert-Regular', 'Roobert-Bold';
}

.iconic-neutral-35 {
  color: #535256 !important;
}

.display-large {
  font-family: 'Roboto';
  font-size: 57px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px;
}

.display-medium {
  font-family: 'Roboto';
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px;
}

.display-small {
  font-family: 'Roboto';
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
}

.headline-large {
  font-family: 'Roboto';
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}

.headline-medium {
  font-family: 'Roboto';
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}

.headline-small {
  font-family: 'Roboto';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

@import 'styles/titles.scss';
@import 'styles/labels.scss';
@import 'styles/body.scss';

.section-header {
  border: #d9d9d9;
  border-width: 0.5px;
  border-style: solid;
}

.section-left {
  border-right: #d9d9d9;
  border-right-width: 0.5px;
  border-style: solid;
}

.custom-tooltip {
  position: relative;
  background-color: #263238;
}

.custom-tooltip::after {
  content: '';
  position: absolute;
  bottom: calc(100% + 8px); /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #263238 transparent; /* Adjust border-color as needed */
}

.status-chip {
  min-width: fit-content !important;
}

.mat-error {
  color: #ba1a1a !important;
  padding: 0.5rem 1rem;
  border-radius: 0.125rem;
}

.iconic-button-toggle-group.mat-button-toggle-group {
  outline: 1px solid black;
}

.iconic-button-toggle-group.mat-button-toggle-group .mat-button-toggle {
  font-weight: 400;
}

.hiddenCanvasElement {
  display: none;
}
