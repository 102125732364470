.body-large-text {
  font-family: 'Roboto' !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.body-medium-text {
  font-family: 'Roboto' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.body-small-text {
  font-family: 'Roboto' !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.body-large-numbers {
  font-family: 'Roboto' !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.body-medium-numbers {
  font-family: 'Roboto' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.body-small-numbers {
  font-family: 'Roboto' !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
